.overflow {
  overflow: auto !important;
}

.color--green {
  color: green;
}

.color--red {
  color: red;
}

.link {
  cursor: pointer;
  color: blue;
  &:hover {
    text-decoration: underline;
  }
}
