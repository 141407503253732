// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$barnabe-blue: (
  50: #e0e0ef,
  100: #b3b3d7,
  200: #8080bd,
  300: #4d4da2,
  400: #26268e,
  500: #00007a,
  600: #000072,
  700: #000067,
  800: #00005d,
  900: #00004a,
  A100: #7e7eff,
  A200: #4b4bff,
  A400: #1818ff,
  A700: #0000fd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$barnabe-orange: (
  50: #fef2e6,
  100: #fcdfc1,
  200: #fac998,
  300: #f8b36f,
  400: #f7a350,
  500: #f59331,
  600: #f48b2c,
  700: #f28025,
  800: #f0761f,
  900: #ee6413,
  A100: #ffffff,
  A200: #fff0e9,
  A400: #ffceb6,
  A700: #ffbd9c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$admin-primary: mat.define-palette($barnabe-blue);
$admin-accent: mat.define-palette($barnabe-orange, A200, A100, A400);

// The warn palette is optional (defaults to red).
$admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-theme: mat.define-light-theme(
  (
    color: (
      primary: $admin-primary,
      accent: $admin-accent,
      warn: $admin-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($admin-theme);

@font-face {
  font-family: "PaytoneOne";
  src: url("assets/fonts/PaytoneOne.eot");
  src: url("assets/fonts/PaytoneOne.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/PaytoneOne.woff2") format("woff2"),
    url("assets/fonts/PaytoneOne.woff") format("woff"),
    url("assets/fonts/PaytoneOne.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* You can add global styles to this file, and also import other style files */
@import "./scss/utility.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PaytoneOne", Roboto, Arial, Helvetica, sans-serif !important;
}
h1 {
  font-size: 2.4em !important;
  margin-top: 20px !important;
  margin-bottom: 15px !important;
}
h2 {
  font-size: 2em !important;
  margin-top: 35px !important;
  margin-bottom: 20px !important;
}
h3 {
  font-size: 1.6em !important;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

.sentMail {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid rgb(226, 226, 226);
}

.buyer {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid rgb(226, 226, 226);
}

.buyerDate {
  display: inline;
}

.mat-radio-button {
  margin-left: 16px;
}

.close.mat-button {
  float: right;
  top: -24px;
  right: -24px;
}

.has_paid {
  background-color: #e69138;
}
.online {
  background-color: #1fb805;
}
.to_expired {
  background-color: #42bfff;
}
.expired {
  background-color: #ffe042;
}
.sold {
  background-color: rgb(255, 136, 136);
}
.pause {
  background-color: grey;
}
/* .sold {
  background-color: rgba(255, 130, 4, 0.45);
} */
